import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#1f8c3a",
		},
	},
	typography: {
		fontFamily: '"Mulish",sans-serif',
	},
});

export { theme };
