import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

export const clauseList = createAsyncThunk("clauseList", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER.get(url);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const clauseView = createAsyncThunk("clauseView", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER.get(url);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const clauseAddEdit = createAsyncThunk("clauseAddEdit", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.post(
			url,
			{
				...data,
			},
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const clauseDelete = createAsyncThunk("clauseDelete", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.delete(url, {
			...data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
