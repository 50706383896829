import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

export const activityList = createAsyncThunk(
  "activityList",
  async (params, thunkApi) => {
    const { url = "", data } = params;
    try {
      const response = await SERVER.get(
        `${url}?from_date=${data?.from_date}&to_date=${
          data?.to_date
        }&per_page=10&page=${data?.page ?? 1}`
      );
      console.log(response);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
