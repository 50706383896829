import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
	name: "common",
	initialState: {
		modal: {
			open: false,
			api: null,
			content: null,
			title: null,
		},
	},
	reducers: {
		setModal: (state, { payload }) => {
			state.modal.api = payload.api;
			state.modal.content = payload.content;
			state.modal.title = payload.title;
			state.modal.open = true;
		},
		setModalReset: (state) => {
			state.modal.open = false;
			state.modal.api = null;
			state.modal.content = null;
			state.modal.title = null;
		},
	},
});
export const { setModal, setModalReset } = commonSlice.actions;
export default commonSlice.reducer;
