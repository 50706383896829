import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "../../index";
export const agentMentorshipList = createAsyncThunk(
    "mentorshipList",
    async (params, thunkApi) => {
      const { url = "" } = params;
      try {
        const response = await SERVER.get(url);
        return response.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );
