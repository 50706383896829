import { createSlice } from "@reduxjs/toolkit";
import { preconstructionAgentList } from "redux/api/service/agent/preconstructionService";
const agentPreconstructionCases=[
    {
        api:preconstructionAgentList,
        name:"preconstructionAgentList",
    },
];
let initialState={};
agentPreconstructionCases.forEach((api) => {
    initialState[api.name] = {
      loading: false,
      data: null,
      error: null,
    };
  });
  export const agentPreconstructionSlice = createSlice({
    name: "agentPreconstruction",
    initialState,
    extraReducers: (builder) => {
        agentPreconstructionCases.map((cases) => {
        builder
          .addCase(cases.api.fulfilled, (state, { payload }) => {
            state[cases.name].loading = false;
            state[cases.name].data = payload;
            state[cases.name].error = null;
          })
          .addCase(cases.api.pending, (state) => {
            state[cases.name].loading = true;
            state[cases.name].error = null;
          })
          .addCase(cases.api.rejected, (state, { payload }) => {
            state[cases.name].loading = false;
            state[cases.name].error = payload;
          });
      });
    },
  });
  
  export default agentPreconstructionSlice.reducer;
  