import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "../../index";

export const agentVendorList = createAsyncThunk(
  "agentVendorList",
  async (param, thunkApi) => {
    const { url = "", params = {} } = param;
    try {
      const response = await SERVER.get(url, {
        params, 
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const agentVendorCategory = createAsyncThunk(
  "agentVendorCategory",
  async (params, thunkApi) => {
    const { url = "" } = params;
    try {
      const response = await SERVER.get(url);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const agentVendorView = createAsyncThunk(
  "agentVendorView",
  async (params, thunkApi) => {
    const { url = "" } = params;
    try {
      const response = await SERVER.get(url);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
