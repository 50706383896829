import { createSlice } from "@reduxjs/toolkit";
import { calenderAgentList } from "redux/api/service/agent/agentCalenderService";




const calenderAgentCases = [
    {
        api: calenderAgentList,
        name: "calenderAgentList"
    }
]

let initialState = {};
calenderAgentCases.forEach((api) => {
	initialState[api.name] = {
		loading: false,
		data: null,
		error: null,
	};
});

export const calenderAgentSlice = createSlice({
	name: "calender_agent",
	initialState,
	extraReducers: (builder) => {
		calenderAgentCases.forEach((cases) => {
			builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload?.data ?? null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].data = null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = null;
					state[cases.name].error = payload;
				});
		});
		return;
	},
});

export default calenderAgentSlice.reducer
