import { createSlice } from "@reduxjs/toolkit";
import { formAddEdit, formDelete, formList, formReArrange, formView } from "redux/api/service/formService";

const formCases = [
	{
		api: formList,
		name: "formList",
	},
	{
		api: formView,
		name: "formView",
	},
	{
		api: formAddEdit,
		name: "formAddEdit",
	},
	{
		api: formDelete,
		name: "formDelete",
	},
	{
		api: formReArrange,
		name: "formReArrange",
	},
];

let initialState = {};
formCases.forEach((api) => {
	initialState[api.name] = {
		loading: false,
		data: null,
		error: null,
	};
});

export const formSlice = createSlice({
	name: "form",
	initialState,
	extraReducers: (builder) => {
		formCases.forEach((cases) => {
			builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload?.data ?? null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].error = null;
					state[cases.name].data = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].error = payload;
					state[cases.name].data = null;
				});
		});
		return;
	},
});

export default formSlice.reducer;
