import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "../../index";

export const agentFormList = createAsyncThunk("agentFormList", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER.get(url);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const agentFormView = createAsyncThunk("agentFormView", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER.get(url);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const agentFormAddEdit = createAsyncThunk("agentFormAddEdit", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.post(
			url,
			{
				...data,
			}
			// {
			// 	headers: {
			// 		"Content-Type": "multipart/form-data",
			// 	},
			// }
		);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const agentFileUpload = createAsyncThunk("agentFileUpload", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.post(
			url,
			{
				...data,
			},
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const agentFormDelete = createAsyncThunk("agentFormDelete", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.delete(url, {
			...data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
