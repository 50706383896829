/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Stack, Container, Hidden, IconButton, Drawer } from "@mui/material";
import { Logo } from "utills/images";
import MenuIcon from "@mui/icons-material/Menu";

function AdminLayout() {
	const {formId, clauseId, agentId, vendorId, mentorshipId, technologyId, videoId} = useParams()
	const location = useLocation();
	const navigate = useNavigate();
	const pathName = location.pathname.split("/").pop();
	const [access, setAccess] = useState(true);
	const [openDrawer, setOpenDrawer] = useState(false);

	const toggleDrawer = () => {
		setOpenDrawer(!openDrawer);
	};

	const token = localStorage.getItem("token");
	const roleName = localStorage.getItem("roleName");

	useEffect(() => {
		roleName === "admin" ? setAccess(true) : setAccess(true);
	}, []);

	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (access === false) {
			navigate("/404");
		}
	}, [access, navigate]);

	return (
		<Box>
			{access && (
				<Box>
					<Box className="authLayoutLogo">
						<img src={Logo} alt="logo" />
					</Box>
					{pathName !== "dashboard" && (
						<Box sx={{ my: 4 }}>
							<Container>
								<Hidden mdUp>
									<IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
										<MenuIcon />
									</IconButton>
								</Hidden>
								<Stack direction={{ md: "row", sm: "column" }} justifyContent={"space-around"} alignItems={"center"} className="dashboardBox">
									<Link to="/admin/dashboard" className="dashboardLinkBox">
										Home
									</Link>
									<Link to="admin/forms" className={pathName === "forms" || pathName === `create` ||  pathName === `${formId}` || pathName === `${clauseId}`  ? "activeMenu dashboardLinkBox" : "dashboardLinkBox"}>
										Forms
									</Link>
									<Link to="admin/videos" className={pathName === "videos" || pathName === `${videoId}` ? "activeMenu dashboardLinkBox" : "dashboardLinkBox"}>
										Videos
									</Link>
									<Link
										// to="/admin/preconstruction"
										to={"https://www.condob2b.com/"}
										target="_blank"
										className={pathName === "preconstruction" ? "activeMenu dashboardLinkBox" : "dashboardLinkBox"}
									>
										Preconstruction
									</Link>
									<Link to="admin/agents" className={pathName === "agents" || pathName === `${agentId}` ?  "activeMenu dashboardLinkBox" : "dashboardLinkBox"}>
										Agents
									</Link>
									<Link to="admin/vendors" className={pathName === "vendors"  || pathName === `${vendorId}` ? "activeMenu dashboardLinkBox" : "dashboardLinkBox"}>
										Vendors
									</Link>
									<Link to="/admin/mentorship" className={pathName === "mentorship" || pathName === `${mentorshipId}` ? "activeMenu dashboardLinkBox" : "dashboardLinkBox"}>
										Mentorship
									</Link>
									<Link to="/admin/technology" className={pathName === "technology"|| pathName === `${technologyId}` ? "activeMenu dashboardLinkBox" : "dashboardLinkBox"}>
										Technology Hub
									</Link>
									<Link to="/admin/calender" className={pathName === "calender" ? "activeMenu dashboardLinkBox" : "dashboardLinkBox"}>
									Calender
									</Link>
									<Link to="admin/settings" className={pathName === "settings" || pathName === "activitylog" ? "activeMenu dashboardLinkBox" : "dashboardLinkBox"}>
										Settings
									</Link>
								</Stack>
							</Container>
							<Drawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
									sx={{
										width: 200,
										padding: "20px",
									}}
								>
									<Link to="/admin/dashboard" className="dashboardLinkBox1">
										Home
									</Link>
									<Link to="/admin/forms" className={pathName === "forms" ? "activeMenu dashboardLinkBox1" : "dashboardLinkBox1"} onClick={toggleDrawer}>
										Forms
									</Link>
									<Link to="/admin/videos" className={pathName === "videos" ? "activeMenu dashboardLinkBox1" : "dashboardLinkBox1"} onClick={toggleDrawer}>
										Videos
									</Link>
									<Link
										// to="/admin/preconstruction"
										to={"https://www.condob2b.com/"}
										target="_blank"
										className={pathName === "preconstruction" ? "activeMenu dashboardLinkBox1" : "dashboardLinkBox1"}
										onClick={toggleDrawer}
									>
										Preconstruction
									</Link>
									<Link to="/admin/agents" className={pathName === "agents" ? "activeMenu dashboardLinkBox1" : "dashboardLinkBox1"} onClick={toggleDrawer}>
										Agents
									</Link>
									<Link to="/admin/vendors" className={pathName === "vendors" ? "activeMenu dashboardLinkBox1" : "dashboardLinkBox1"} onClick={toggleDrawer}>
										Vendors
									</Link>
									<Link to="/admin/mentorship" className={pathName === "mentorship" ? "activeMenu dashboardLinkBox1" : "dashboardLinkBox1"} onClick={toggleDrawer}>
										Mentorship
									</Link>
									<Link to="/admin/calender" className={pathName === "calender" ? "activeMenu dashboardLinkBox1" : "dashboardLinkBox1"} onClick={toggleDrawer}>
										Training Calendar
									</Link>
									<Link to="/admin/technology" className={pathName === "technology" ? "activeMenu dashboardLinkBox1" : "dashboardLinkBox1"} onClick={toggleDrawer}>
										Technology Hub
									</Link>
									<Link
										to="/admin/settings"
										className={pathName === "settings" || pathName === "activitylog" ? "activeMenu dashboardLinkBox1" : "dashboardLinkBox1"}
										onClick={toggleDrawer}
									>
										Settings
									</Link>
								</Stack>
							</Drawer>
						</Box>
					)}
					<Box>
						<Outlet />
					</Box>
				</Box>
			)}
		</Box>
	);
}

export default AdminLayout;
