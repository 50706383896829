/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import { activityList } from "redux/api/service/activitySerivce";


const activityCases = [
  {
    api: activityList,
    name: "activityList",
  },
];
let initialState = {};

activityCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  extraReducers: (builder) => {
   activityCases.map((cases) => {
     builder
       .addCase(cases.api.fulfilled, (state, { payload }) => {
         state[cases.name].loading = false;
         state[cases.name].data = payload;
         state[cases.name].error = null;
       })
       .addCase(cases.api.pending, (state) => {
         state[cases.name].loading = true;
         state[cases.name].error = null;
       })
       .addCase(cases.api.rejected, (state, { payload }) => {
         state[cases.name].loading = false;
         state[cases.name].error = payload;
       });
   });
  },
});

export default activitySlice.reducer;
