/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import {
  technologyList,
  technologyView,
  technologyAddEdit,
  technologyDelete,
  technologyStatus,
} from "redux/api/service/technologyService";

const technologyCases = [
  {
    api: technologyList,
    name: "technologyList",
  },
  {
    api: technologyView,
    name: "technologyView",
  },
  {
    api: technologyAddEdit,
    name: "technologyAddEdit",
  },
  {
    api: technologyDelete,
    name: "technologyDelete",
  },
  {
    api: technologyStatus,
    name: "technologyStatus",
  },
];
let initialState = {};

technologyCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const technologySlice = createSlice({
  name: "technology",
  initialState,
  extraReducers: (builder) => {
    technologyCases.map((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
        });
    });
  },
});

export default technologySlice.reducer;
