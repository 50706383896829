/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import {
  agentAddEdit,
  agentDelete,
  agentList,
  agentView,
  agentStatus,
  agentEmail,
} from "redux/api/service/agentService";

const agentCases = [
  {
    api: agentList,
    name: "agentList",
  },
  {
    api: agentView,
    name: "agentView",
  },
  {
    api: agentAddEdit,
    name: "agentAddEdit",
  },
  {
    api: agentDelete,
    name: "agentDelete",
  },
  {
    api: agentStatus,
    name: "agentStatus",
  },
  {
    api: agentEmail,
    name: "agentEmail",
  },
];

let initialState = {
};
agentCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  extraReducers: (builder) => {
    agentCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
        });
    });
    return;
  },
});

export default agentSlice.reducer;
