/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import {
  agentVendorList,
  agentVendorView,
  agentVendorCategory,
} from "redux/api/service/agent/agentVendorService";

const agentVendorCases = [
  {
    api: agentVendorList,
    name: "agentVendorList",
  },
  {
    api: agentVendorView,
    name: "agentVendorView",
  },
  {
    api: agentVendorCategory,
    name: "agentVendorCategory",
  },
];

let initialState = {};
agentVendorCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const agentVendorSlice = createSlice({
  name: "agentVendor",
  initialState,
  extraReducers: (builder) => {
    agentVendorCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
        });
    });
    return;
  },
});

export default agentVendorSlice.reducer;
