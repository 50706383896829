import { createSlice } from "@reduxjs/toolkit";
import { agentMentorshipList } from "redux/api/service/agent/agentMentorshipService";
const agentMentorshipCases=[
    {
        api:agentMentorshipList,
        name:'agentMentorshipList'
    },
];
let initialState={};
agentMentorshipCases.forEach((api)=>{
    initialState[api.name] = {
        loading: false,
        data: null,
        error: null,
      };
});
export const agentMentorshipSlice = createSlice({
    name: "agentmentorship",
    initialState,
    extraReducers: (builder) => {
        agentMentorshipCases.map((cases) => {
        builder
          .addCase(cases.api.fulfilled, (state, { payload }) => {
            state[cases.name].loading = false;
            state[cases.name].data = payload;
            state[cases.name].error = null;
          })
          .addCase(cases.api.pending, (state) => {
            state[cases.name].loading = true;
            state[cases.name].error = null;
          })
          .addCase(cases.api.rejected, (state, { payload }) => {
            state[cases.name].loading = false;
            state[cases.name].error = payload;
          });
      });
    },
  });
  
  export default agentMentorshipSlice.reducer;