import { createSlice } from "@reduxjs/toolkit";
import { clauseAddEdit, clauseDelete, clauseList, clauseView } from "redux/api/service/clauseService";

const clauseCases = [
	{
		api: clauseList,
		name: "clauseList",
	},
	{
		api: clauseView,
		name: "clauseView",
	},
	{
		api: clauseAddEdit,
		name: "clauseAddEdit",
	},
	{
		api: clauseDelete,
		name: "clauseDelete",
	},
];

let initialState = {};
clauseCases.forEach((api) => {
	initialState[api.name] = {
		loading: false,
		data: null,
		error: null,
	};
});

export const clauseSlice = createSlice({
	name: "clause",
	initialState,
	extraReducers: (builder) => {
		clauseCases.forEach((cases) => {
			builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload?.data ?? null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].data = null;
					state[cases.name].error = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = null;
					state[cases.name].error = payload;
				});
		});
		return;
	},
});

export default clauseSlice.reducer;
