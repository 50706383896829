import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "redux/slice/authSlice";
import formReducer from "redux/slice/formSlice";
import vendorReducer from "redux/slice/vendorSlice";
import agentReducer from "redux/slice/agentSlice";
import clauseReducer from "redux/slice/clauseSlice";
import commonReducer from "redux/slice/commonSlice";
import technologyReducer from "redux/slice/technologyslice";
import agentFormReducer from "redux/slice/agent/agentFormSlice";
import videoReducer from "redux/slice/videoSlice";
import preconstructionReducer from "redux/slice/preconstructionSlice";
import settingReducer from "redux/slice/settingSlice";
import activityReducer from "redux/slice/activitySlice";
import communityReducer  from "redux/slice/agent/communityHubSlice";
import agentVendorReducer from "redux/slice/agent/agentVendorSlice";
import agentVideoReducer from "redux/slice/agent/videoSlice";
import mentorshipReducer from "redux/slice/mentorshipSlice"
import agentMentorshipReducer from "redux/slice/agent/agentMentorshipSlice";
import agentProfileReducer from "redux/slice/agent/agentProfileSlice"; 
import agentPreconstructionReducer from "redux/slice/agent/agentPreconstructionSlice"
import calenderReducer from "redux/slice/calenderSlice";
import agentcalenderReducer from "redux/slice/agent/agentCalenderSlice";

export const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  vendor: vendorReducer,
  agent: agentReducer,
  clause: clauseReducer,
  common: commonReducer,
  technology: technologyReducer,
  agentForm: agentFormReducer,
  video: videoReducer,
  preconstruction: preconstructionReducer,
  setting: settingReducer,
  activity: activityReducer,
  community: communityReducer,
  agentVendor: agentVendorReducer,
  agentvideo: agentVideoReducer,
  mentorship: mentorshipReducer,
  agentmentorship:agentMentorshipReducer,
  profile: agentProfileReducer,
  agentPreconstruction: agentPreconstructionReducer,
  calender : calenderReducer,
  agentcalender: agentcalenderReducer

});
