import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "views/layouts/adminLayout";
import AuthLayout from "./views/layouts/authLayout";
import AgentLayout from "views/layouts/agentLayout";
import "./index.css";
import "./App.css";
import "./formclause.css";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "components/modal/deleteModal";
import { setModalReset } from "redux/slice/commonSlice";
import RouterLoader from "components/loaders/routerLoader";
import "react-big-calendar/lib/css/react-big-calendar.css";


// import ForgotPassword from "views/pages/auth/forgot-password";

const NotFound = lazy(() => import("views/pages/notFound"));
const UnAuthed = lazy(() => import("views/pages/404"));

const Login = lazy(() => import("./views/pages/auth/login"));
const AgentLogin = lazy(() => import("./views/pages/auth/login/agentLogin"));

const ForgotPassword = lazy(() => import("./views/pages/auth/forgot-password/index"));
const AgentForgotFrom = lazy(() => import("./views/pages/auth/forgot-password/agentForgotFrom"));
const ResetPassword = lazy(() => import("./views/pages/auth/reset-password/index"));
const AgentResetFrom = lazy(() => import("./views/pages/auth/reset-password/agentResetFrom"));

const Dashboard = lazy(() => import("./views/pages/dashboard"));
const AgentDashboard = lazy(() => import("./views/pages/dashboard/agentDashboard"));
const TraningAgentCalender = lazy(() => import("./views/pages/agents/trainingAgent_calender/ViewAgentCalender"));
const AgentsList = lazy(() => import("./views/pages/agents"));
const AgentsView = lazy(() => import("./views/pages/agents/agentView"));
const VendorsComponent = lazy(() => import("./views/pages/vendors"));
const VendorsView = lazy(() => import("./views/pages/vendors/vendorview"));
const TechnologyComponent = lazy(() => import("./views/pages/technology"));
const EdittechnologyComponent = lazy(() => import("./views/pages/technology/edit"));
const MentorshipComponent = lazy(() => import("./views/pages/mentorship"));
const EditmentorshipComponent = lazy(() => import("./views/pages/mentorship/edit"));
//Form
const FormsComponent = lazy(() => import("./views/pages/forms"));
const FormViewComponent = lazy(() => import("./views/pages/forms/view"));
const CreateFormComponent = lazy(() => import("./views/pages/forms/create"));
const EditFormComponent = lazy(() => import("./views/pages/forms/edit"));
//Clause
const CreateClauseComponent = lazy(() => import("./views/pages/clause/create"));
const EditClauseComponent = lazy(() => import("./views/pages/clause/edit"));
const ViewClause = lazy(() => import("./views/pages/clause/view"));

const SettingsPage = lazy(() => import("views/pages/settings"));
const ActivityLogPage = lazy(() => import("views/pages/settings/activityLog"));
const SettingEditComponent = lazy(() => import("./views/pages/settings/adminedit"));
const TrainingCalender = lazy(() => import("./views/pages/training_calender/ViewCalender"));

// video
const VideoComponent = lazy(() => import("views/pages/videos"));
const EditVideo = lazy(() => import("views/pages/videos/edit"));
// const ViedoCreate = lazy(() => import("views/pages/videos/create"));
// preconstruction

const PreconstructionComponent = lazy(() => import("views/pages/preconstruction"));

// agents
const AgentFormList = lazy(() => import("views/pages/agentPages/form"));
const AgentFormCreate = lazy(() => import("views/pages/agentPages/form/create"));
const AgentVideoList = lazy(() => import("views/pages/agentPages/videos"));
const AgentVendorList = lazy(() => import("views/pages/agentPages/vendors"));
const AgentVendorsView = lazy(() => import("views/pages/agentPages/vendors/agentvendorview"));
const AgentMentorShip = lazy(() => import("views/pages/agentPages/mentorship"));
const AgentCommunityHubComponent = lazy(() => import("views/pages/agentPages/communityHub"));
const ProfilePage = lazy(() => import("views/pages/agentPages/profile"));
const ProfileEditComponent = lazy(() => import("./views/pages/agentPages/profile/agentedit"));
const AgentPreconstruction = lazy(() => import("views/pages/agentPages/preConstruction"));

function App() {

	const dispatch = useDispatch();
	const { open, content, api } = useSelector((state) => state.common.modal);
	const handleCloseModal = () => {
		dispatch(setModalReset());
	};

	return (
		<>
			{open && (
				<DeleteModal
					open={open}
					close={() => {
						handleCloseModal();
					}}
					title={"Delete"}
					content={content}
					submit={() => {
						api();
					}}
					// loading={stateValues.deleteLoading}
				/>
			)}
			<ToastContainer
				position="bottom-left"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<Suspense fallback={<RouterLoader />}>
				<Routes>
					<Route element={<AuthLayout />}>
						<Route
							path="/admin/login"
							element={<Login />}
						></Route>

						<Route
							path="/"
							element={<AgentLogin />}
						></Route>
						<Route
							path="/agent/login"
							element={<AgentLogin />}
						></Route>
						<Route
							path="/admin/forgot-password"
							element={<ForgotPassword />}
						></Route>
						<Route
							path="/agent/forgot-password"
							element={<AgentForgotFrom />}
						></Route>
						<Route
							path="/admin/reset-password"
							element={<ResetPassword />}
						></Route>
						<Route
							path="/agent/reset-password"
							element={<AgentResetFrom />}
						></Route>
					</Route>
					<Route element={<AdminLayout />}>
						<Route
							path="/admin/dashboard"
							element={<Dashboard />}
						></Route>
						<Route
							path="/admin/calender"
							element={<TrainingCalender />}
						></Route>
						<Route
							path="/admin/agents"
							element={<AgentsList />}
						></Route>
						<Route
							path="/admin/agents/view/:agentId"
							element={<AgentsView />}
						></Route>
						<Route
							path="/admin/vendors"
							element={<VendorsComponent />}
						></Route>
						<Route
							path="/admin/vendors/view/:vendorId"
							element={<VendorsView />}
						></Route>
						<Route
							path="/admin/technology"
							element={<TechnologyComponent />}
						></Route>
						<Route
							path="/admin/technology/edit/:technologyId"
							element={<EdittechnologyComponent />}
						></Route>
						<Route
							path="/admin/mentorship"
							element={<MentorshipComponent />}
						></Route>
						<Route
							path="/admin/mentorship/edit/:mentorshipId"
							element={<EditmentorshipComponent />}
						></Route>

						{/* Forms */}
						<Route
							path="/admin/forms"
							element={<FormsComponent />}
						></Route>
						<Route
							path="/admin/forms/view/:formId"
							element={<FormViewComponent />}
						></Route>
						<Route
							path="/admin/forms/create"
							element={<CreateFormComponent />}
						></Route>
						<Route
							path="/admin/forms/edit/:formId"
							element={<EditFormComponent />}
						></Route>
						<Route
							path="/admin/agent/forms/:formId"
							element={<AgentFormCreate readOnly={true} />}
						></Route>
						{/* Forms */}
						{/* Clause */}
						<Route
							path="/admin/clause/create"
							element={<CreateClauseComponent />}
						></Route>
						<Route
							path="/admin/clause/edit/:clauseId"
							element={<EditClauseComponent />}
						></Route>
						<Route
							path="/admin/clause/view/:clauseId"
							element={<ViewClause />}
						></Route>
						{/* Clause */}
						<Route
							path="/admin/settings"
							element={<SettingsPage />}
						></Route>
						<Route
							path="/admin/activitylog"
							element={<ActivityLogPage />}
						></Route>
						<Route
							path="/admin/settings/adminedit"
							element={<SettingEditComponent />}
						></Route>

						{/* Video */}
						<Route
							path="/admin/videos"
							element={<VideoComponent />}
						></Route>
						<Route
							path="/admin/preconstruction"
							element={<PreconstructionComponent />}
						></Route>
						<Route
							path="/admin/videos/edit/:videoId"
							element={<EditVideo />}
						></Route>
						{/* <Route path="/admin/videos/create/" element={<ViedoCreate />}></Route> */}
					</Route>
					<Route element={<AgentLayout />}>
						<Route
							path="/agent/dashboard"
							element={<AgentDashboard />}
						></Route>
						<Route
							path="/agent/calender"
							element={<TraningAgentCalender/>}
						></Route>

						{/* Forms */}
						<Route
							path="/agent/forms"
							element={<AgentFormList />}
						></Route>
						<Route
							path="/agent/forms/:formId"
							element={<AgentFormCreate readOnly={false} />}
						></Route>

						{/* Forms */}
						<Route
							path="/agent/videos"
							element={<AgentVideoList />}
						></Route>
						<Route
							path="/agent/vendors"
							element={<AgentVendorList />}
						></Route>
						<Route
							path="/agent/vendors/view/:agentvendorId"
							element={<AgentVendorsView />}
						></Route>
						<Route
							path="/agent/mentorship"
							element={<AgentMentorShip />}
						></Route>
						<Route
							path="/agent/profile"
							element={<ProfilePage />}
						></Route>
						<Route
							path="/agent/profile/agentedit"
							element={<ProfileEditComponent />}
						></Route>
						<Route
							path="/agent/communityhub"
							element={<AgentCommunityHubComponent />}
						></Route>
						<Route
							path="/agent/preconstruction"
							element={<AgentPreconstruction />}
						></Route>
					</Route>
					<Route
						path="*"
						element={<NotFound />}
					/>
					<Route
						path="/404"
						element={<UnAuthed />}
					/>
				</Routes>
			</Suspense>
		</>
	);
}

export default App;
