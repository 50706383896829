import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

export const technologyAddEdit = createAsyncThunk(
  "technologyAddEdit",
  async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
      const response = await SERVER.post(
        url,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const technologyList = createAsyncThunk(
  "technologyList",
  async (params, thunkApi) => {
    const { url = "" } = params;
    try {
      const response = await SERVER.get(url);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const technologyView = createAsyncThunk(
  "technologyView",
  async (params, thunkApi) => {
    const { url = "" } = params;
    try {
      const response = await SERVER.get(url);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const technologyDelete = createAsyncThunk(
  "technologyDelete",
  async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
      const response = await SERVER.delete(url, {
        ...data,
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const technologyStatus = createAsyncThunk(
  "technologyStatus",
  async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    console.log("url", url);
    console.log("data", data);
    try {
      const response = await SERVER.patch(url, {
        ...data,
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
