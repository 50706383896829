import { createSlice } from "@reduxjs/toolkit";

import {videoView, videoList,videoAddEdit,videoDelete } from "redux/api/service/videoService";
const videoCases =[
    {
        api: videoList,
        name:"videoList",
    },
    {
        api: videoAddEdit,
        name: "videoAddEdit",
      },
      {
        api: videoDelete,
        name: "videoDelete",
      },
      {
        api: videoView,
        name: "videoView",
      },
];
let initialState ={};
videoCases.forEach((api) => {
    initialState[api.name] = {
      loading: false,
      data: null,
      error: null,
    };
  });
  export const videoSlice= createSlice({
    name:"video",
    initialState,
    extraReducers:(builder)=>{
        videoCases.map((cases)=>{
            builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].error = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].error = payload;
				});
        });
    },
  });
  export default videoSlice.reducer;