import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export function errorAlert(msg) {
	if (typeof msg === "object") {
		for (const key in msg) {
			const errorMsg = msg[key];
			if (Array.isArray(errorMsg)) {
				toast.error(errorMsg[0]);
			} else {
				toast.error(errorMsg);
			}
		}
	} else {
		toast.error(msg);
	}
}

export function successAlert(msg) {
	toast.success(msg);
}
export function warning(msg) {
	toast.info(msg);
}

export const useAppDispatch = () => {
	const dispatch = useDispatch();
	return dispatch;
};

export const handlePriceBlur = (e, setValue) => {
	const { name, value } = e.target;
	// let lastLength = value.slice(-3)
	const split = value.split(".");
	if (value !== "") {
		if (split.length < 2) {
			const newValue = value + ".00";
			setValue(name, newValue);
		} else if (split.length >= 2) {
			setValue(name, value);
		}
	} else {
		if (split.length < 2) {
			// setValue(name, "0.00");
		} else {
			setValue(name, value);
		}
	}
};

// calendarUtils.js

const formatDate = (date) => {

	if (date instanceof Date) {
	  // If date is of type 'Date'
	  return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
	} else if (typeof date === 'string') {
	  // If date is of type 'string'
	  return dayjs(date).format('DD MMM YYYY');
	}
	// Default to the original value if not a recognized type
	return date;
  };

  const allColor = [{
	"event": {
		"1": {
		 "background": "#a4bdfc",
		 "foreground": "#1d1d1d"
		},
		"2": {
		 "background": "#7ae7bf",
		 "foreground": "#1d1d1d"
		},
		"3": {
		 "background": "#dbadff",
		 "foreground": "#1d1d1d"
		},
		"4": {
		 "background": "#ff887c",
		 "foreground": "#1d1d1d"
		},
		"5": {
		 "background": "#fbd75b",
		 "foreground": "#1d1d1d"
		},
		"6": {
		 "background": "#ffb878",
		 "foreground": "#1d1d1d"
		},
		"7": {
		 "background": "#46d6db",
		 "foreground": "#1d1d1d"
		},
		"8": {
		 "background": "#e1e1e1",
		 "foreground": "#1d1d1d"
		},
		"9": {
		 "background": "#5484ed",
		 "foreground": "#1d1d1d"
		},
		"10": {
		 "background": "#51b749",
		 "foreground": "#1d1d1d"
		},
		"11": {
		 "background": "#dc2127",
		 "foreground": "#1d1d1d"
		}
	   }
	}
  ]
  
  const eventObject = allColor[0]?.event;

//   export const allevnts =  [
//  	{
//  	  "title": "Event 1",
//  	  "start": new Date("2024-02-01T08:00:00.000Z"),
//  	  "end": new Date("2024-02-01T10:00:00.000Z")
//  	},
//    ]
export const allevents = (eventDetails) => {
	let colorStyle = {};

  if (eventObject && eventObject[eventDetails?.colorId]) {
    const colorInfo = eventObject[eventDetails?.colorId]
    colorStyle = {
      backgroundColor: colorInfo.background,
      foregroundColor: colorInfo.foreground,
    }
  } else {
    colorStyle = {
      backgroundColor: "#004D9B",
	}
}
  return {
    id: eventDetails?.id,
    title: eventDetails?.title,
    allDay: false,
    start: new Date(eventDetails?.start),
    end: new Date(eventDetails?.end),
	description: eventDetails?.description,
	color: eventDetails?.colorId,
	style: colorStyle,
	creator: eventDetails?.creator?.email,
	location: eventDetails?.location,
	attachments: eventDetails?.attachments || [],
	attendees: eventDetails?.attendees || [],
  };
  
 
};
