/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import {
  vendorList,
  vendorAddEdit,
  vendorDelete,
  vendorView,
  vendorStatus,
} from "redux/api/service/vendorService";

const vendorCases = [
  {
    api: vendorList,
    name: "vendorList",
  },
  {
    api: vendorView,
    name: "vendorView",
  },
  {
    api: vendorAddEdit,
    name: "vendorAddEdit",
  },
  {
    api: vendorDelete,
    name: "vendorDelete",
  },
  {
    api: vendorStatus,
    name: "vendorStatus",
  },
];
let initialState = {};

vendorCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const vendorSlice = createSlice({
	name: "vendor",
	initialState,
	extraReducers: (builder) => {
		vendorCases.map((cases) => {
			builder
				.addCase(cases.api.fulfilled, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].data = payload;
					state[cases.name].error = null;
				})
				.addCase(cases.api.pending, (state) => {
					state[cases.name].loading = true;
					state[cases.name].error = null;
				})
				.addCase(cases.api.rejected, (state, { payload }) => {
					state[cases.name].loading = false;
					state[cases.name].error = payload;
				});
		});
	},
});

export default vendorSlice.reducer;
