import { createSlice } from "@reduxjs/toolkit";
import { mentorshipAddEdit, mentorshipDelete, mentorshipList, mentorshipView } from "redux/api/service/mentorshipSerivce";
const mentorshipCases = [
  {
    api: mentorshipList,
    name: "mentorshipList",
  },
  {
    api: mentorshipAddEdit,
    name: "mentorshipAddEdit",
  },
  {
    api: mentorshipDelete,
    name: "mentorshipDelete",
  },
  {
    api: mentorshipView,
    name: "mentorshipView",
  },
];
let initialState = {};
mentorshipCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});
export const mentorshipSlice = createSlice({
  name: "mentorship",
  initialState,
  extraReducers: (builder) => {
    mentorshipCases.map((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
        });
    });
  },
});
export default mentorshipSlice.reducer;
