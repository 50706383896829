import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

export const authLogin = createAsyncThunk("authLogin", async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
        const response = await SERVER.post(url, {
            ...data,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const forgetPassword = createAsyncThunk("forgetPassword", async (params, thunkApi) => {
    const { url, data } = params;
    try {
        const response = await SERVER.post(url, {
            ...data,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const resetPassword = createAsyncThunk("resetPassword", async (params, thunkApi) => {
    const { url, data } = params;
    try {
        const response = await SERVER.post(url, {
            ...data,
        });
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});

export const logout = createAsyncThunk("logout", async (params, thunkApi) => {
    const { url } = params;
    try {
        const response = await SERVER.post(url);
        return response.data;
    } catch (error) {
        return thunkApi.rejectWithValue(error);
    }
});
