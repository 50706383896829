import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const RouterLoader = () => {
	return (
		<Box
			sx={{
				display: "flex",
				height: "100vh",
				width: "100vw",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<CircularProgress />
		</Box>
	);
};

export default RouterLoader;
