import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

export const preconstructionAddEdit = createAsyncThunk(
  "preconstructionAddEdit",
  async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
      const response = await SERVER.post(url, {
        ...data,
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);