import React from "react"
import { Outlet } from 'react-router-dom'
import { Box, Stack } from "@mui/material";

function AuthLayout() {
    return (
        <Box className="authBG">
            <Stack direction={'column'} alignItems={'center'} justifyContent={'space-evenly'} sx={{ height: '100%' }}>
                <Outlet />
            </Stack>
        </Box>
    )
}

export default AuthLayout