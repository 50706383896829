import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

export const vendorAddEdit = createAsyncThunk("vendorAddEdit", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.post(
      url,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const vendorList = createAsyncThunk("vendorList", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER.get(url);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const vendorView = createAsyncThunk("vendorView", async (params, thunkApi) => {
    const { url = "" } = params;
    try {
      const response = await SERVER.get(url);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const vendorDelete = createAsyncThunk("vendorDelete", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.delete(url, {
      ...data,
    });
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const vendorStatus = createAsyncThunk(
  "vendorStatus",
  async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    console.log("url", url);
    console.log("data", data);
    try {
      const response = await SERVER.patch(url, {
        ...data,
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
