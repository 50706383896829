/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import { getMe, updateMeData, changePassword } from "redux/api/service/settingService";

const settingCases = [
  {
    api: getMe,
    name: "getMe",
  },
  {
    api: updateMeData,
    name: "updateMeData",
  },
  {
    api: changePassword,
    name: "changePassword",
  },
];
let initialState = {};

settingCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  extraReducers: (builder) => {
   settingCases.map((cases) => {
     builder
       .addCase(cases.api.fulfilled, (state, { payload }) => {
         state[cases.name].loading = false;
         state[cases.name].data = payload;
         state[cases.name].error = null;
       })
       .addCase(cases.api.pending, (state) => {
         state[cases.name].loading = true;
         state[cases.name].error = null;
       })
       .addCase(cases.api.rejected, (state, { payload }) => {
         state[cases.name].loading = false;
         state[cases.name].error = payload;
       });
   });
  },
});

export default settingSlice.reducer;
