import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";
export const videoList = createAsyncThunk("videoList", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER.get(url);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const videoAddEdit = createAsyncThunk("vendorAddEdit", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.post(
      url,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const videoDelete = createAsyncThunk("vendorDelete", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.delete(url, {
      ...data,
    });
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const videoView = createAsyncThunk("vendorView", async (params, thunkApi) => {
    const { url = "" } = params;
    try {
      const response = await SERVER.get(url);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);


