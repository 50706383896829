import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";


export const calenderList = createAsyncThunk("calenderList", async(params, thunkApi) => {
    const { url = "" , allDate} = params;
    try {
        const response = await SERVER.get(url, {
            params :{
                start_date: allDate?.start,
                end_date:   allDate?.end
            }
        });
        return response.data;
    }  catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});