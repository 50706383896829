import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";
export const mentorshipList = createAsyncThunk(
  "mentorshipList",
  async (params, thunkApi) => {
    const { url = "" } = params;
    try {
      const response = await SERVER.get(url);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const mentorshipAddEdit = createAsyncThunk(
  "mentorshipAddEdit",
  async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
      const response = await SERVER.post(
        url,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const mentorshipDelete = createAsyncThunk(
  "mentorshipDelete",
  async (params, thunkApi) => {
    const { url = "", data = {} } = params;
    try {
      const response = await SERVER.delete(url, {
        ...data,
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const mentorshipView = createAsyncThunk(
  "mentorshipView",
  async (params, thunkApi) => {
    const { url = "" } = params;
    try {
      const response = await SERVER.get(url);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
