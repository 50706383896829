/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Drawer, Hidden, IconButton, Stack } from "@mui/material";
import { Logo } from "utills/images";
import MenuIcon from "@mui/icons-material/Menu";

function AgentValue() {
	const location = useLocation();
	const navigate = useNavigate();
	const pathName = location.pathname.split("/").pop();

	const [access, setAccess] = useState(true);

	const [openDrawer, setOpenDrawer] = useState(false);

	const toggleDrawer = () => {
		setOpenDrawer(!openDrawer);
	};

	const token = localStorage.getItem("token");
	const roleName = localStorage.getItem("roleName");

	useEffect(() => {
		roleName !== "admin" ? setAccess(true) : setAccess(false);
	}, []);

	useEffect(() => {
		if (!token) {
			navigate("/");
		}
		if (access === false) {
			navigate("/404");
		}
	}, [access, navigate]);

	return (
		<Box>
			{access && (
				<Box>
					<Box className="authLayoutLogo">
						<img
							src={Logo}
							alt="logo"
						/>
					</Box>
					{pathName !== "dashboard" && (
						<Box sx={{ my: 4, px: "7%" }}>
							<Hidden mdUp>
								<IconButton
									edge="start"
									color="inherit"
									aria-label="menu"
									onClick={toggleDrawer}
								>
									<MenuIcon />
								</IconButton>
							</Hidden>
							<Stack
								direction={{ md: "row", sm: "column" }}
								justifyContent={"space-around"}
								alignItems={"center"}
								className="dashboardBox"
							>
								<Link
									to="/agent/dashboard"
									className="dashboardLinkBox"
								>
									Home
								</Link>
								<Link
									to="agent/forms"
									className={
										pathName === "forms"
											? "activeMenu dashboardLinkBox"
											: "dashboardLinkBox"
									}
								>
									Forms
								</Link>
								<Link
									to={`/agent/videos`}
									className={
										pathName === "videos"
											? "activeMenu dashboardLinkBox"
											: "dashboardLinkBox"
									}
								>
									Videos
								</Link>
								<Link
									// to={`/agent/preconstruction`}
									to={"https://www.condob2b.com/"}
									target="_blank"
									className={
										pathName === "preconstruction"
											? "activeMenu dashboardLinkBox"
											: "dashboardLinkBox"
									}
								>
									Preconstruction
								</Link>
								<Link
									to="agent/vendors"
									className={
										pathName === "vendors"
											? "activeMenu dashboardLinkBox"
											: "dashboardLinkBox"
									}
								>
									Vendors
								</Link>
								<Link
									to="/agent/mentorship"
									className={
										pathName === "mentorship"
											? "activeMenu dashboardLinkBox"
											: "dashboardLinkBox"
									}
								>
									Mentorship
								</Link>
								<Link
									to="/agent/calender"
									className={
										pathName === "calender"
											? "activeMenu dashboardLinkBox"
											: "dashboardLinkBox"
									}
									
								>
									Training Calendar
								</Link>
								<Link
									to="/agent/communityhub"
									className={
										pathName === "communityhub"
											? "activeMenu dashboardLinkBox"
											: "dashboardLinkBox"
									}
								>
									Community Hub
								</Link>
								<Link
									to="/agent/profile"
									className={
										pathName === "profile"
											? "activeMenu dashboardLinkBox"
											: "dashboardLinkBox"
									}
								>
									Profile
								</Link>
							</Stack>
							<Drawer
								anchor="left"
								open={openDrawer}
								onClose={toggleDrawer}
							>
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
									sx={{
										width: 200,
										padding: "20px",
									}}
								>
									<Link
										to="/agent/dashboard"
										className="dashboardLinkBox1"
									>
										Home
									</Link>
									<Link
										to="agent/forms"
										className={
											pathName === "forms"
												? "activeMenu dashboardLinkBox1"
												: "dashboardLinkBox1"
										}
									>
										Forms
									</Link>
									<Link
										to={`/agent/videos`}
										className={
											pathName === "videos"
												? "activeMenu dashboardLinkBox1"
												: "dashboardLinkBox1"
										}
									>
										Videos
									</Link>
									<Link
										to={"https://www.condob2b.com/"}
										target="_blank"
										// to={`/agent/preconstruction`}
										className={
											pathName === "preconstruction"
												? "activeMenu dashboardLinkBox1"
												: "dashboardLinkBox1"
										}
									>
										Preconstruction
									</Link>
									<Link
										to="agent/vendors"
										className={
											pathName === "vendors"
												? "activeMenu dashboardLinkBox1"
												: "dashboardLinkBox1"
										}
									>
										Vendors
									</Link>
									<Link
										to="/agent/mentorship"
										className={
											pathName === "mentorship"
												? "activeMenu dashboardLinkBox1"
												: "dashboardLinkBox1"
										}
									>
										Mentorship
									</Link>
									<Link
										to="/agent/calender"
										// className="dashboardLinkBox"
										className={
											pathName === "calender"
												? "activeMenu dashboardLinkBox1"
												: "dashboardLinkBox1"
										}
									>
										Training Calender
									</Link>
									<Link
										to="/agent/communityhub"
										className={
											pathName === "communityhub"
												? "activeMenu dashboardLinkBox1"
												: "dashboardLinkBox1"
										}
									>
										Community Hub
									</Link>
									<Link
										to="/agent/profile"
										className={
											pathName === "profile"
												? "activeMenu dashboardLinkBox1"
												: "dashboardLinkBox1"
										}
									>
										Profile
									</Link>
								</Stack>
							</Drawer>
						</Box>
					)}
					<Box>
						<Outlet />
					</Box>
				</Box>
			)}
		</Box>
	);
}

export default AgentValue;
