/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";
import { preconstructionAddEdit } from "redux/api/service/preconstructionService";

const preconstructionCases = [
  {
    api: preconstructionAddEdit,
    name: "preconstructionAddEdit",
  },
];
let initialState = {};

preconstructionCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const preconstructionSlice = createSlice({
  name: "preconstruction",
  initialState,
  extraReducers: (builder) => {
    preconstructionCases.map((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
        });
    });
  },
});

export default preconstructionSlice.reducer;
