import { createSlice } from "@reduxjs/toolkit";
import { communityList} from "redux/api/service/agent/communityHubService";
const communityCases = [
    {
        api: communityList,
        name: "communityList",
      },
];
let initialState = {};

communityCases.forEach((api) => {
    initialState[api.name] = {
      loading: false,
      data: null,
      error: null,
    };
  });
  export const communitySlice = createSlice({
    name: "community",
    initialState,
    extraReducers: (builder) => {
        communityCases.map((cases) => {
        builder
          .addCase(cases.api.fulfilled, (state, { payload }) => {
            state[cases.name].loading = false;
            state[cases.name].data = payload;
            state[cases.name].error = null;
          })
          .addCase(cases.api.pending, (state) => {
            state[cases.name].loading = true;
            state[cases.name].error = null;
          })
          .addCase(cases.api.rejected, (state, { payload }) => {
            state[cases.name].loading = false;
            state[cases.name].error = payload;
          });
      });
    },
  });
  
  export default communitySlice.reducer;
  