import { createAsyncThunk } from "@reduxjs/toolkit";
import SERVER from "..";

export const formList = createAsyncThunk("formList", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER.get(url);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const formView = createAsyncThunk("formView", async (params, thunkApi) => {
	const { url = "" } = params;
	try {
		const response = await SERVER.get(url);
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const formReArrange = createAsyncThunk("formReArrange", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.post(url, {
			...data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const formAddEdit = createAsyncThunk("formAddEdit", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.post(url, {
			...data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
export const formDelete = createAsyncThunk("formDelete", async (params, thunkApi) => {
	const { url = "", data = {} } = params;
	try {
		const response = await SERVER.delete(url, {
			...data,
		});
		return response.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
