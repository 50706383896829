/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import {
    agentFormList,
    agentFormView,
    agentFormAddEdit,
    agentFormDelete,
    agentFileUpload
} from "redux/api/service/agent/agentFormService";

const agentFormCases = [
  {
    api: agentFormList,
    name: "agentFormList",
  },
  {
    api: agentFormView,
    name: "agentFormView",
  },
  {
    api: agentFormAddEdit,
    name: "agentFormAddEdit",
  },
  {
    api: agentFileUpload,
    name: "agentFileUpload",
  },
  {
    api: agentFormDelete,
    name: "agentFormDelete",
  },
];

let initialState = {
};
agentFormCases.forEach((api) => {
  initialState[api.name] = {
    loading: false,
    data: null,
    error: null,
  };
});

export const agentFormSlice = createSlice({
  name: "agentForm",
  initialState,
  extraReducers: (builder) => {
    agentFormCases.forEach((cases) => {
      builder
        .addCase(cases.api.fulfilled, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].data = payload?.data ?? null;
          state[cases.name].error = null;
        })
        .addCase(cases.api.pending, (state) => {
          state[cases.name].loading = true;
          state[cases.name].error = null;
        })
        .addCase(cases.api.rejected, (state, { payload }) => {
          state[cases.name].loading = false;
          state[cases.name].error = payload;
        });
    });
    return;
  },
});

export default agentFormSlice.reducer;
